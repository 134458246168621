
    import { defineComponent } from 'vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
    import { BTooltip } from 'bootstrap-vue';

    /**
     * Generalised sidebar component for the settings and information sidebars
     * Includes animation for showing and hiding sidebar
     * The 'side' prop says which side of the screen the sidebar should be
     */
    export default defineComponent({
        props: {
            side: {
                type: String,
                required: true,
            },
            title: {
                type: String,
            },
            show: {
                type: Boolean,
                required: true,
            },
            showHelp: {
                type: Boolean,
            },
        },
        components: {
            FontAwesomeIcon,
            BTooltip,
        },
        setup() {
            return {
                questionIcon: faQuestionCircle,
            };
        },
    });
