import mitt from 'mitt';

/**
 * Used as a global event bus so components can talk to each other directly
 */
export const emitter = mitt();

export default function useEmitter() {
    return emitter;
}

export const onEvent = (eventName: string, func: (event: any) => void) => {
    emitter.on(eventName, func);
};

export const emitGlobal = (eventName: string, data?: any) => {
    emitter.emit(eventName, data);
};

export const Events = {
    VIEWER_LAUNCHED: 'viewer-launched',
    ENVIRONMENTS_LOADED: 'environments-loaded',
    MODEL_LOADED: 'model-loaded',
};

export const waitForEvent = (eventName: string) => {
    return new Promise((resolve) => {
        const handler = (val: any) => {
            emitter.off(eventName, handler);
            resolve(val);
        };

        emitter.on(eventName, handler);
    });
};
