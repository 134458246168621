
    import { defineComponent, computed, ref } from 'vue';
    import { EnvironmentManager } from '@poplar-studio/model-viewer';
    import SideBar from '@/components/SideBar.vue';
    import { baseScene, environmentManager, shadowPlane, model } from '@/components/viewer-components';
    import { Events, onEvent, emitGlobal } from '@/hooks/useEmitter';
    import { IEnvironmentOption } from '@/types';

    export default defineComponent({
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            environments: {
                type: Array,
                required: true,
            },
            disableShadows: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            SideBar,
        },
        setup(props) {
            const lightIntensity = ref(1);

            const intensity = computed({
                get() {
                    return lightIntensity.value;
                },
                set(val: number) {
                    lightIntensity.value = val;
                    baseScene.renderer.toneMappingExposure = val;
                    baseScene.render();
                },
            });

            const backgroundColour = ref('#e9e9e9');
            const currentEnv = ref('solid-colour');

            const shadows = ref(true);

            const shadowsEnabled = computed({
                get() {
                    return shadows.value;
                },
                set(val: boolean) {
                    shadows.value = val;
                    if (val) {
                        applyShadows();
                    } else {
                        shadowPlane.removeFromScene();
                    }
                },
            });

            const setBackgroundToColour = () => {
                environmentManager.load(EnvironmentManager.HDR_OPTIONS.photography_studio.url, true);
                environmentManager.setBackgroundToGradientFromColour(backgroundColour.value);
                currentEnv.value = 'solid-colour';
            };

            const handleBackgroundColourChange = (e: InputEvent) => {
                const colour = (e.target as HTMLInputElement).value;
                backgroundColour.value = colour;
                shadowsEnabled.value = true;
                environmentManager.setBackgroundToGradientFromColour(colour);
            };

            const handleEnvironmentChange = async (data: IEnvironmentOption) => {
                await environmentManager.load(data.url, false);
                shadowsEnabled.value = false;
                currentEnv.value = data.name;
            };

            const applyNeutralLighting = () => {
                environmentManager.load(EnvironmentManager.HDR_OPTIONS.neutral_correction.url, true);
                environmentManager.setBackgroundToGradientFromColour('#e9e9e9');
                shadowsEnabled.value = true;
                currentEnv.value = 'neutral';
            };

            const applyShadows = () => {
                if (!props.disableShadows) {
                    shadowPlane.setOrientation('floor');
                    shadowPlane.setFromObject(model);
                    shadowPlane.addToScene();
                }
            };

            onEvent(Events.VIEWER_LAUNCHED, async () => {
                if (props) {
                    await environmentManager.preload([
                        EnvironmentManager.HDR_OPTIONS.neutral_correction.url,
                        EnvironmentManager.HDR_OPTIONS.photography_studio.url,
                        ...props.environments.map((env: any) => env.url),
                    ]);
                    applyNeutralLighting();
                    emitGlobal(Events.ENVIRONMENTS_LOADED);
                }
            });

            onEvent(Events.MODEL_LOADED, async () => {
                if (shadowsEnabled.value) {
                    applyShadows();
                }
            });

            return {
                lightIntensity,
                intensity,
                backgroundColour,
                currentEnv,
                setBackgroundToColour,
                handleBackgroundColourChange,
                handleEnvironmentChange,
                applyNeutralLighting,
                shadowsEnabled,
            };
        },
    });
