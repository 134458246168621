
    import { defineComponent, ref, toRefs, watch } from 'vue';
    import QRCode from 'qrcode';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faTimes } from '@fortawesome/free-solid-svg-icons';

    export default defineComponent({
        name: 'qr-modal',
        components: {
            FontAwesomeIcon,
        },
        props: {
            url: {
                type: String,
                required: true,
            },
            show: {
                type: Boolean,
                required: true,
            },
        },
        setup(props, context) {
            const dataURL = ref('');
            const { url } = toRefs(props);

            const generateQRCode = (qrUrl: string) => {
                if (qrUrl !== '') {
                    QRCode.toDataURL(qrUrl, {
                        margin: 0,
                        width: 200,
                        color: {
                            light: '#ffffff00',
                        },
                    }).then((generateUrl: string) => {
                        dataURL.value = generateUrl;
                    });
                }
            };

            generateQRCode(props.url);

            watch(url, generateQRCode);

            const close = () => {
                context.emit('close');
            };

            return {
                dataURL,
                xIcon: faTimes,
                close,
            };
        },
    });
