
    import { defineComponent, ref } from 'vue';
    import { BButton } from 'bootstrap-vue';

    export default defineComponent({
        components: {
            BButton,
        },
        setup(props, { emit }) {
            const fileInput = ref<HTMLInputElement | null>(null);

            const handleButtonClick = () => {
                fileInput.value?.click();
            };

            const handleFileUpload = () => {
                if (fileInput.value?.files) {
                    emit('file-uploaded', URL.createObjectURL(fileInput.value.files[0]));
                }
            };

            return {
                handleButtonClick,
                handleFileUpload,
                fileInput,
            };
        },
    });
