
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import moment from 'moment';
    import { IAnnotation } from '../types';
    import { BButton } from 'bootstrap-vue';
    import { model } from './viewer-components';
    import { THREE } from '@poplar-studio/model-viewer';

    @Component({
        components: {
            BButton,
        },
        filters: {
            formatDate(value: string) {
                return moment(value).format('D MMMM YYYY [at] h:mm A');
            },
        },
    })
    export default class Annotation extends Vue {
        @Prop() annotation!: IAnnotation;
        @Prop() displayNum!: number;
        @Prop({ default: true }) canEdit!: boolean;
        @Prop({ default: false }) visible!: boolean;
        @Prop({ default: false }) creating!: boolean;

        private editing: boolean = false;
        private text: string = '';
        private isOpen = false;
        private saveButtonDisabled = false;

        @Watch('annotation.text')
        onTextChange() {
            this.editing = false;
        }

        mounted() {
            this.text = this.annotation.text;

            if (this.creating) {
                this.isOpen = true;
                // $nextTick is needed for focussing the textarea, I cannot remember exactly.
                // Think it's something to do with the three.js controls events interfering with the click events
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.focusTextInput();
                    }, 0);
                });
            }

            if (!this.visible) {
                (this.$el as HTMLDivElement).style.display = 'none';
            }

            model.addAnnotation(
                this.annotation.id as string,
                new THREE.Vector3(this.annotation.position.x, this.annotation.position.y, this.annotation.position.z),
                this.$el as HTMLElement,
                this.visible
            );
        }

        beforeDestroy() {
            model.removeAnnotation(this.annotation.id as string);
        }

        toggleOpen() {
            this.isOpen = !this.isOpen;
        }

        inputClick(e: MouseEvent) {
            e.stopPropagation();
            this.focusTextInput();
        }

        textClick() {
            if (this._canEdit) {
                this.toggleEditing();
                this.$nextTick(() => {
                    (this.$refs.textInput as HTMLTextAreaElement).focus();
                });
            }
        }

        cancelClick() {
            this.text = this.annotation.text;
            this.toggleEditing();
        }

        cancelNewAnnotationClick() {
            this.$emit('cancel-create');
        }

        toggleEditing() {
            if (this._canEdit) {
                this.editing = !this.editing;
            }
        }

        saveAnnotation() {
            if (this.text !== this.annotation.text) {
                this.$emit('saved', {
                    ...this.annotation,
                    text: this.text,
                });
            } else {
                this.editing = false;
            }
        }

        createAnnotation() {
            this.saveButtonDisabled = true;
            this.saveAnnotation();
        }

        deleteAnnotation() {
            this.$emit('deleted', this.annotation);
        }

        focusTextInput() {
            (this.$refs.textInput as HTMLTextAreaElement).focus();
        }

        isFullScreen(): boolean {
            return document.fullscreenElement !== null;
        }

        get _canEdit() {
            /* eslint-disable-next-line */
            if (this.annotation.hasOwnProperty('canEdit')) {
                return this.annotation.canEdit;
            }

            return this.canEdit;
        }
    }
