import Vue from 'vue';
import App from './App.vue';
import VueMq from 'vue-mq/dist/vue-mq';
import * as Sentry from '@sentry/vue';
import './assets/styles/main.scss';

Sentry.init({
    Vue,
    environment: process.env.VUE_APP_ENV,
    dsn: 'https://407225b11d4944ed81f380bbe9072851@o622134.ingest.sentry.io/6502408',
});

Vue.config.productionTip = false;

Vue.use(VueMq, {
    breakpoints: {
        xs: 576,
        sm: 768,
        md: 992,
        lg: 1200,
        xl: Infinity,
    },
    defaultBreakpoint: 'lg',
});

new Vue({
    render: (h) => h(App),
}).$mount('#app');
