/**
 * This file contains the components needed from the model viewer package needed in the app
 * They are initialised here so they can then be imported into the vue components where they are needed
 */

import {
    AnnotationHelpers,
    BaseScene,
    Controls,
    GLTFModelWithAnnotations,
    EnvironmentManager,
    ShadowPlane,
} from '@poplar-studio/model-viewer';

export const baseScene = new BaseScene({
    width: 500,
    height: 500,
    withCSSRenderer: true,
    vr: false,
    rendererOpts: {
        enableShadows: true,
        alpha: true,
    },
});

export const controls = new Controls(baseScene);

export const annotationHelpers = new AnnotationHelpers(baseScene);

export const model = new GLTFModelWithAnnotations(baseScene, {
    castShadow: true,
});

export const shadowPlane = new ShadowPlane(baseScene);

let frameCount = 0;
baseScene.onRender(() => {
    if (frameCount === 10) {
        for (let i = 0; i < model.annotations.length; i++) {
            annotationHelpers.checkAnnotationVisibility(model.annotations[i], model.children);
        }
        frameCount = 0;
    }

    frameCount++;
});

export type GLTFModelWithAnnotation = GLTFModelWithAnnotations;

export const environmentManager = new EnvironmentManager(baseScene);
