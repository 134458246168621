
    import { Component, Vue } from 'vue-property-decorator';
    import ModelViewer from '@/components/ModelViewer.vue';
    import { IAnnotation, WindowMessage } from './types';
    import { EnvironmentManager } from '@poplar-studio/model-viewer';
    import FileDropzone from '@/components/FileDropzone.vue';
    import UploadButton from '@/components/UploadButton.vue';

    @Component({
        components: {
            ModelViewer,
            FileDropzone,
            UploadButton,
        },
    })
    export default class App extends Vue {
        environments = [
            EnvironmentManager.HDR_OPTIONS.hotel_room,
            EnvironmentManager.HDR_OPTIONS.kitchen,
            EnvironmentManager.HDR_OPTIONS.outdoors,
            EnvironmentManager.HDR_OPTIONS.office,
        ];

        annotations: IAnnotation[] = [];

        viewerLoaded: boolean = false;
        loadError = false;
        screenshotMode = false;
        modelUrl: string | null = null;
        initialRotation: [number, number] | null = null;
        enableAnnotations = false;
        canEdit = false;
        viewerId: string | null = null;
        usdz: string | null = null;
        openAr = false;
        disableShadows = false;
        enableAr = false;
        fileUploadError = '';

        created() {
            // Get the app config from the URL
            const params = new URLSearchParams(window.location.search);

            // Url of the GLB file
            if (params.has('url')) {
                this.modelUrl = params.get('url') as string;
                this.enableAr = true;
            }

            // Used in CMS preview queue worker, gives the scene a transparent background
            if (params.has('screenshot')) {
                this.screenshotMode = Boolean(params.get('screenshot'));
            }

            // Set the initial rotation of the camera, used by preview queue worker
            if (params.has('initialRotation')) {
                const val = params.get('initialRotation') as string;
                this.initialRotation = val.split(',').map((str) => parseInt(str)) as [number, number];
            }

            // Enable annotation functionality or not
            if (params.has('enableAnnotations')) {
                this.enableAnnotations = Boolean(params.get('enableAnnotations'));
            }

            // Gives the viewer an ID that is sent with every post message. Allows the parent window to know which iframe the message came from
            if (params.has('viewerId')) {
                this.viewerId = params.get('viewerId');
            }

            // Url of a corresponding USDZ file for AR. Will attempt to use three.js USDZ exporter if a URL is not supplies
            this.usdz = params.get('usdz');

            // Whether to open straight into AR mode
            this.openAr = Boolean(params.get('ar'));

            // Whether to open straight into AR mode
            if (params.has('disableShadows')) {
                this.disableShadows = Boolean(params.get('disableShadows'));
            }

            /**
             * Listening for messages from the parent window
             * Each message is given a 'type' so we know what to do with the data it is carrying
             */
            window.addEventListener('message', (e) => {
                const { data } = e;

                if (data.type) {
                    switch (data.type) {
                        case 'INIT':
                            this.canEdit = data.payload.canEdit;
                            this.annotations = this.annotations.concat(data.payload.annotations);
                            break;
                        case 'ANNOTATION_DELETED':
                            this.annotations = this.annotations.filter((item) => item.id !== data.payload.annotationId);
                            break;
                        case 'ANNOTATION_CREATED':
                            this.annotations.push(data.payload.annotation);
                            break;
                        case 'ANNOTATION_UPDATED':
                            /* eslint-disable-next-line no-case-declarations */
                            const index = this.annotations.findIndex((item) => item.id === data.payload.annotation.id);

                            this.annotations.splice(index, 1, data.payload.annotation);
                    }
                }
            });
        }

        handleViewerLoaded() {
            this.viewerLoaded = true;
        }

        handleLoadError() {
            this.loadError = true;
        }

        handleAnnotationSaved(annotation: IAnnotation) {
            this.sendMessageToParent('UPDATE_ANNOTATION', { annotation });
        }

        handleAnnotationDeleted(annotation: IAnnotation) {
            this.sendMessageToParent('DELETE_ANNOTATION', { annotation });
        }

        handleCreateNewAnnotation(annotation: IAnnotation) {
            this.sendMessageToParent('CREATE_ANNOTATION', { annotation });
        }

        sendMessageToParent(type: string, payload: any) {
            const messageBody: WindowMessage = {
                type,
                payload,
            };

            if (this.viewerId) {
                messageBody.viewerId = this.viewerId;
            }

            parent.postMessage(messageBody, '*');
        }

        handleFileDrop(e: string) {
            this.modelUrl = e;
        }

        handleFileDropError(e: string) {
            this.fileUploadError = e;
        }
    }
