
    import { defineComponent } from 'vue';

    export default defineComponent({
        setup(props, { emit }) {
            const handleDrop = (e: DragEvent) => {
                e.preventDefault();

                if (e.dataTransfer) {
                    if (e.dataTransfer.files[0].name.includes('.glb')) {
                        emit('file-dropped', URL.createObjectURL(e.dataTransfer.files[0]));
                    } else {
                        emit('error', 'Only GLB files can be viewed');
                    }
                }
            };

            const handleDragOver = (e: DragEvent) => {
                e.preventDefault();

                if (e.dataTransfer) {
                    e.dataTransfer.dropEffect = 'copy';
                }
            };

            return {
                handleDrop,
                handleDragOver,
            };
        },
    });
